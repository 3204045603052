import { useConfig } from "../auth"
import { redirectToProvider } from "../utils/allauth"
// import GoogleOneTap from './GoogleOneTap'

export default function ProviderList(props) {
  const config = useConfig()
  const providers = config.data.socialaccount.providers
  const label = props.signup? "Sign up": "Sign in"
  if (!providers.length) {
    return null
  }
  return (
    <>
      {/* <GoogleOneTap process={props.process} /> */}
      <div className="d-grid gap-3">
        {providers.map((provider) => {
          return (
            <button
              key={provider.id}
              className="btn btn-outline-light text-black fw-medium border"
              type="button"
              onClick={() => redirectToProvider(provider.id, props.callbackURL, props.process)}
            >
              {" "}
              {label} with {provider.name}
            </button>
          )
        })}
      </div>
    </>
  )
}
