export function CardWrap({ children }) {
  return <div className="col bg-white shadow rounded-3 m-2 p-2">{children}</div>
}

export function SectionWrap({ children }) {
  return (
    <div className="row m-0 p-0">
      <CardWrap>{children}</CardWrap>
    </div>
  )
}

export default function MainWrap({ NoticePanel, width, children }) {
  const styles = {}
  if (width !== "auto") {
    styles.maxWidth = "600px"
  }

  return (
    <div className="container  m-0 p-0 py-3" style={styles}>
      {NoticePanel ? <p className="text-body-tertiary fs-08 m-0 px-3 py-1">{NoticePanel}</p> : null}
      <SectionWrap>{children}</SectionWrap>
    </div>
  )
}
