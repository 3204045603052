import { Link } from "react-router-dom"

export default function FooterItem(props) {
  const cls = "nav-link px-2 text-body-secondary link-underline-secondary link-offset-2"
  const label = props.icon ? `${props.icon} ${props.name}` : props.name
  let atag
  if (props.justtext) {
    atag = <span className={cls}>{label}</span>
  } else if (props.href) {
    atag = (
      <a className={cls} href={props.href}>
        {label}
      </a>
    )
  } else {
    atag = (
      <Link className={cls} href={props.href}>
        {label}
      </Link>
    )
  }
  return <li className="nav-item">{atag}</li>
}
