import { getCSRFToken } from "./django"

const BASE_URL = "" // if this file is used outside user-hub this url should be set to process.env.REACT_APP_MY_DATENO_BASE_URL

export const URLs = Object.freeze({
  SAVE_HISTORY_RECORD: BASE_URL + "/api/search/history/queries/",
  GET_HISTORY_RECORD_LIST: BASE_URL + "/api/search/history/queries/",
  DELETE_HISTORY_RECORD: BASE_URL + "/api/search/history/queries/",
  SEND_FEEDBACK: BASE_URL + "/api/users/feedback/",
})

async function request(method, path, data) {
  const options = {
    method,
    credentials: "include",
    headers: {
      "X-CSRFToken": getCSRFToken(),
    },
  }
  if (typeof data !== "undefined") {
    options.body = JSON.stringify(data)
    options.headers["Content-Type"] = "application/json"
  }
  const resp = await fetch(path, options)
  const msg = await resp.json()

  return msg
}

export async function createSearchHistoryRecord(query) {
  return await request("POST", URLs.SAVE_HISTORY_RECORD, { query })
}

export async function getSearchHistoryRecordList(nextUrl) {
  // FIXME why http is returned from backend (DRF/ViewSet issue?)
  const url = nextUrl ? nextUrl.replace("http://", "https://") : undefined
  return await request("GET", url || URLs.GET_HISTORY_RECORD_LIST)
}

export async function deleteSearchHistoryRecord(recordId) {
  return await request("DELETE", URLs.DELETE_HISTORY_RECORD + `${recordId}/`)
}

export async function sendUsersFeedback(data) {
  return await request("POST", URLs.SEND_FEEDBACK, data)
}
