import { useState } from "react"
import FeedbackModal from "./FeedbackModal"

const MockSubscribe = () => {
  const [showModal, setShowModal] = useState(false)
  const [feedbackSent, setFeedbackSent] = useState(false)
  const [feedbackFailed, setFeedbackFailed] = useState(false)

  const handleCloseModal = () => {
    setShowModal(false)
  }
  const handleFeedbackSuccess = () => {
    setFeedbackSent(true) // Set success message state
  }
  const handleFeedbackError = (msg) => {
    setFeedbackFailed(msg) // Set success message state
  }

  return (
    <div className="">
      {feedbackSent && (
        <div className="alert alert-success mt-3 text-center" role="alert">
          Thank you for your feedback!
        </div>
      )}
      {feedbackFailed && (
        <div className="alert alert-error mt-3 text-center" role="alert">
          {feedbackFailed || "Sorry. Something didn't go right."}
        </div>
      )}
      <div className="">
        <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#feedbackModal">
          Subscribe
        </button>
      </div>
      <FeedbackModal
        show={showModal}
        handleClose={handleCloseModal}
        onFeedbackSuccess={handleFeedbackSuccess}
        onFeedbackError={handleFeedbackError}
      />
    </div>
  )
}

export default MockSubscribe
