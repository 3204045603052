import { useState } from "react"
import { Navigate, Link } from "react-router-dom"
import { logout } from "../utils/allauth"
import Button from "../components/Button"
import MainWrap from "../components/MainWrap"

export default function Logout() {
  const [response, setResponse] = useState({ fetching: false, content: null })

  function submit() {
    setResponse({ ...response, fetching: true })
    logout()
      .then((content) => {
        setResponse((r) => {
          return { ...r, content }
        })
      })
      .catch((e) => {
        console.error(e)
        window.alert(e)
      })
      .then(() => {
        setResponse((r) => {
          return { ...r, fetching: false }
        })
      })
  }
  if (response.content) {
    return <Navigate to="/" />
  }

  return (
    <MainWrap
      NoticePanel={
        <>
          Back to <Link to="/settings">Account Settings</Link>
        </>
      }
    >
      <p className="">Are you sure you want to logout?</p>

      <Button disabled={response.fetching} onClick={() => submit()}>
        Sign out
      </Button>
    </MainWrap>
  )
}
