import { getCSRFToken } from "./django"
import { CLIENT, Client } from "./allauth"

const BASE_URL = `/api/${CLIENT}/v1`
const ACCEPT_JSON = {
  accept: "application/json",
}

export const URLs = Object.freeze({
  // Meta
  CONFIG: BASE_URL + "/config", // 404 for now

  // Kong Keys
  KEYS: BASE_URL + "/keys",
  PRIMARY_KEY: BASE_URL + "/keys/primary",

  // User management
  USER_DETAILS: BASE_URL + "/user",

  // payments
  CREATE_CHECKOUT_SESSION: BASE_URL + "/checkout/create-checkout-session/",
})

const tokenStorage = window.sessionStorage

async function request(method, path, data, headers) {
  const options = {
    method,
    headers: {
      ...ACCEPT_JSON,
      ...headers,
    },
  }
  // Don't pass along authentication related headers to the config endpoint.
  if (path !== URLs.CONFIG) {
    if (CLIENT === Client.BROWSER) {
      options.headers["X-CSRFToken"] = getCSRFToken()
    } else if (CLIENT === Client.APP) {
      // IMPORTANT!: Do NOT use `Client.APP` in a browser context, as you will
      // be vulnerable to CSRF attacks. This logic is only here for
      // development/demonstration/testing purposes...
      options.headers["User-Agent"] = "django-allauth example app" // will be ignored in  a browser context
      options.headers["X-User-Agent"] = "django-allauth example app"
      const sessionToken = tokenStorage.getItem("sessionToken")
      if (sessionToken) {
        options.headers["X-Session-Token"] = sessionToken
      }
      const accessToken = tokenStorage.getItem("accessToken")
      if (accessToken) {
        options.headers["Authorization"] = `Bearer ${accessToken}`
        options.headers["X-Access-Token"] = accessToken
      }
    }
  }

  if (typeof data !== "undefined") {
    options.body = JSON.stringify(data)
    options.headers["Content-Type"] = "application/json"
  }
  const resp = await fetch(path, options)
  const msg = await resp.json()
  // if (msg.status === 410) {
  //   tokenStorage.removeItem('sessionToken')
  // }
  // if (msg.meta?.session_token) {
  //   tokenStorage.setItem('sessionToken', msg.meta.session_token)
  // }
  // if ([401, 410].includes(msg.status) || (msg.status === 200 && msg.meta?.is_authenticated)) {
  //   const event = new CustomEvent('allauth.auth.change', { detail: msg })
  //   document.dispatchEvent(event)
  // }
  return msg
}

export async function getPrimaryKey(data) {
  return await request("GET", URLs.PRIMARY_KEY, data)
}

export async function getKeys(data) {
  return await request("GET", URLs.KEYS, data)
}

export async function checkout(data) {
  return await request("GET", URLs.CREATE_CHECKOUT_SESSION, data)
}
export async function getUserDetails(data) {
  return await request("GET", URLs.USER_DETAILS, data)
}
