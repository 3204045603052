import { Link } from "react-router-dom"

export default function SettingItem(props) {

  const cls = "nav-link d-flex align-items-center gap-2 fs-5 text-black fw-light"
  const label = props.icon ? `${props.icon} ${props.name}` : props.name
  return (
    <li className="list-group-item bg-white">
      {props.href ? (
        <a className={cls} href={props.href}>
          {label}
        </a>
      ) : (
        <Link className={cls} to={props.to}>
          {label}
        </Link>
      )}
    </li>
  )
}
