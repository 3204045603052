import { useState } from "react"
import { sendUsersFeedback } from "../utils/restapi"

const FeedbackModal = ({ show, handleClose, onFeedbackError, onFeedbackSuccess }) => {
  const [message, setMessage] = useState("Hello, Dateno!\n\nI'd like to purchase your services.")

  const handleSubmit = async (e) => {
    e.preventDefault()

    const feedbackData = {
      message,
    }

    try {
      await sendUsersFeedback(feedbackData)
      onFeedbackSuccess() // Trigger success action in the parent
      handleClose() // Close the modal after successful submission
    } catch (error) {
      onFeedbackError("Error sending feedback")
    }
  }

  return (
    <div className="modal fade" id="feedbackModal" tabIndex="-1" aria-labelledby="feedbackModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="feedbackModalLabel">
              Thank You for your interest!
            </h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <p>
              We thank you for your interest! Do you want to leave feedback or provide any suggestions for the service?
            </p>
            <div className="form-group">
              <textarea
                className="form-control"
                placeholder="Leave a comment here"
                onChange={(e) => setMessage(e.target.value)}
                style={{ height: "8rem" }}
                value={message}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              data-bs-dismiss="modal"
              onClick={handleSubmit}
              disabled={!message}
            >
              Send Feedback
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeedbackModal
