// https://www.algolia.com/doc/api-reference/widgets/history-router/react/#widget-param-createurl
import { history } from "instantsearch.js/es/lib/routers"
import qs from "qs"
import { createSearchHistoryRecord } from "../utils/restapi"

export function getRouting(searchIndexName) {
  const routing = {
    router: history({
      createURL({ qsModule, location, routeState }) {
        const { origin, pathname, hash } = location
        const indexState = routeState[searchIndexName] || {}

        if (!indexState.query && !indexState.refinementList) {
          return `${origin}${pathname}${hash}`
        }

        const queryString = qsModule.stringify(indexState)
        return `${origin}${pathname}?${queryString}${hash}`
      },
      parseURL({ qsModule, location }) {
        const parsedUrl = qsModule.parse(location.search.slice(1))
        const newRouteState = {}
        newRouteState[searchIndexName] = parsedUrl
        return newRouteState
      },
      cleanUrlOnDispose: false,
    }),
  }

  return routing
}

let timerId
let timeout = 1000

export function getOnStateChange(searchIndexName) {
  const onStateChange = ({ uiState, setUiState }) => {
    // debounce saving history
    clearTimeout(timerId)
    timerId = setTimeout(async () => {
      const indexState = uiState[searchIndexName] || {}
      delete indexState.configure
      const q = qs.stringify(indexState)
      console.log("SAVE HISTORY", q)

      try {
        const response = createSearchHistoryRecord(q) // Передаем поисковую строку
        console.log("SAVEd", response)

        console.log("Search history saved successfully")
      } catch (error) {
        console.error("Error saving search history:", error)
      }
    }, timeout)

    // update UI state anyway
    setUiState(uiState)
  }
  return onStateChange
}

export function queryToObject(query) {
  return qs.parse(query)
}
