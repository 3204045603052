// import IconDateno from "../components/IconDateno.svg?react";

export default function Layout({ children }) {
  return (
    <div className="d-grid gap-2 col-6 mx-auto" style={{ width: "350px" }}>
      {/* <IconDateno className="" /> */}

      {children}
    </div>
  )
}
