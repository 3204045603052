import NavBarItem from "./NavBarItem"
import { getSearchUrl, getApiDocsUrl } from "../utils/helpers"

export default function NavBar() {
  return (
    <>
      <ul className="nav flex-column">
        <NavBarItem to="/" name="My Dateno" />
        <NavBarItem href={getSearchUrl()} name="Search" />
        <NavBarItem to="/history" name="Search history" />
        <NavBarItem to="/checkout" name="Get early access" />
        <NavBarItem href={getApiDocsUrl()} name="API Docs" />
      </ul>

      <hr className="my-3" />

      <ul className="nav flex-column mb-auto">
        <NavBarItem to="/settings" name="Account settings" />
        <NavBarItem to="/account/logout" name="Sign out" />
      </ul>
    </>
  )
}
