import { useEffect, createContext, useState } from "react"
import { getAuth, getConfig } from "../utils/allauth"
import { ReactComponent as IconDateno } from "../components/IconDateno.svg"
import { getSearchUrl, getApiDocsUrl, getPublicPageUrl } from "../utils/helpers"
import FooterItem from "../components/FooterItem"

export const AuthContext = createContext(null)

function Loading() {
  return (
    <div className="d-flex m-5 justify-content-center">
      <div className="spinner-border text-secondary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  )
}

function LoadingError({ config }) {
  return (
    <div className="anon-bg d-flex flex-column h-100">
      <main className="bg-body-custom form-signin w-100 m-auto rounded-4 shadow p-4 py-6" style={{ maxWidth: "400px" }}>
        <div className="d-flex justify-content-center  align-items-center mb-4">
          <IconDateno className="anon-logo" />
          <div className="fs-2 mx-3">Dateno</div>
        </div>

        <h1 className="fs-3 text-center fw-light my-5">{config.status}</h1>

        <p className="text-tertiary text-center fw-light">We suggest come back later.</p>
      </main>
      <footer className="footer m-0  mt-auto mb-0 container-fluid d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
        <ul className="nav col-md-5 justify-content-start">
          <FooterItem href={getPublicPageUrl("conditions")} name="Terms" />
          <FooterItem href={getPublicPageUrl("privacy")} name="Privacy" />
        </ul>

        <ul className="nav col-md-5 justify-content-end">
          <FooterItem href={getSearchUrl()} name="Dateno Search" />
          <FooterItem href={getApiDocsUrl()} name="API Docs" />
          <FooterItem href={getPublicPageUrl("about")} name="About" />
          <FooterItem href={getPublicPageUrl("help")} name="Help" />
          <FooterItem href={getPublicPageUrl("registry")} name="Registry" />
        </ul>
      </footer>
    </div>
  )
}

export function AuthContextProvider(props) {
  const [auth, setAuth] = useState(undefined)
  const [config, setConfig] = useState(undefined)

  useEffect(() => {
    function onAuthChanged(e) {
      setAuth((auth) => {
        if (typeof auth === "undefined") {
          console.log("Authentication status loaded")
        } else {
          console.log("Authentication status updated")
        }
        return e.detail
      })
    }

    document.addEventListener("allauth.auth.change", onAuthChanged)
    getAuth()
      .then((data) => {
        setAuth(data)
      })
      .catch((e) => {
        setAuth(false)
      })
    getConfig()
      .then((data) => setConfig(data))
      .catch((e) => {
        setConfig({ status: "502 Bad Gateway", message: e.message, e })
      })
    return () => {
      document.removeEventListener("allauth.auth.change", onAuthChanged)
    }
  }, [])

  const loading = typeof auth === "undefined" || typeof config === "undefined"
  // It will show Loading Error if getAuth cathes error (like 5xx http status error)
  // The loadingError coponent will show current config even if get Config catches 5xx error too
  return (
    <AuthContext.Provider value={{ auth, config }}>
      {loading ? <Loading /> : auth === false ? <LoadingError config={config} /> : props.children}
    </AuthContext.Provider>
  )
}
