import { Outlet } from "react-router-dom"
import { useUser } from "./auth"
import { useState } from "react"

import PageLayout from "./components/PageLayout"
import PageLayoutAnon from "./components/PageLayoutAnon"

const AuthLayout = () => {
  const [title, setTitle] = useState("")

  return (
    <PageLayout title={title}>
      <Outlet context={{ title, setTitle }} />
    </PageLayout>
  )
}

const AnonLayout = () => {
  const [title, setTitle] = useState("")

  return (
    <PageLayoutAnon>
      <Outlet context={{ title, setTitle }} />
    </PageLayoutAnon>
  )
}

export default function Root() {
  const user = useUser()
  
  return user ? <AuthLayout /> : <AnonLayout />
}
