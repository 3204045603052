export default function FormErrors(props) {
  if (!props.errors || !props.errors.length) {
    return null
  }
  const errors = props.errors.filter((error) => (props.param ? error.param === props.param : error.param == null))
  return (
    <>
      {errors.map((e, i) => (
        <p key={i} className="invalid-feedback d-block">
          {e.message}
        </p>
      ))}
    </>
  )
}
