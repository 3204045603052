import { ReactComponent as IconDateno } from "./IconDateno.svg"

const AnonLayout = ({ children }) => {
  return (
    <div className="anon-bg d-flex flex-column h-100">
      <main className="bg-body-custom form-signin w-100 m-auto rounded-4 shadow p-4 py-6" style={{ maxWidth: "400px" }}>
        <div className="d-flex justify-content-center  align-items-center mb-4">
          <IconDateno className="anon-logo" />
          <div className="fs-2 mx-3">Dateno</div>
        </div>
        {children}
      </main>
    </div>
  )
}

export default AnonLayout
