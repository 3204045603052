import { getSearchUrl, getApiDocsUrl, getPublicPageUrl } from "../utils/helpers"
import FooterItem from "./FooterItem"

export default function Footer() {
  return (
    <footer className="footer m-0  mt-auto mb-0 container-fluid d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
      <ul className="nav col-md-5 justify-content-start">
        <FooterItem to="/" name="© 2024 Dateno" justtext />
        <FooterItem href={getPublicPageUrl("conditions")} name="Terms" />
        <FooterItem href={getPublicPageUrl("privacy")} name="Privacy" />
      </ul>

      <ul className="nav col-md-5 justify-content-end">
        <FooterItem href={getSearchUrl()} name="Home" />
        <FooterItem href={getApiDocsUrl()} name="API Docs" />
        <FooterItem href={getPublicPageUrl("about")} name="About" />
        <FooterItem href={getPublicPageUrl("help")} name="Help" />
        <FooterItem to="/" name="My Dateno" />
        <FooterItem href={getPublicPageUrl("registry")} name="Registry" />
      </ul>
    </footer>
  )
}
