import { useState } from "react"
import FormErrors from "../components/FormErrors"
import { signUp } from "../utils/allauth"
import { Link } from "react-router-dom"
import { useConfig } from "../auth"
import ProviderList from "../socialaccount/ProviderList"
import Button from "../components/Button"
import PageHeaderModal from "./PageHeaderModal"

export default function Signup() {
  const [email, setEmail] = useState("")
  const [password1, setPassword1] = useState("")
  const [password2, setPassword2] = useState("")
  const [password2Errors, setPassword2Errors] = useState([])
  const [response, setResponse] = useState({ fetching: false, content: null })
  const config = useConfig()
  const hasProviders = config.data.socialaccount?.providers?.length > 0

  function submit() {
    if (password2 !== password1) {
      setPassword2Errors([{ param: "password2", message: "Password does not match." }])
      return
    }
    setPassword2Errors([])
    setResponse({ ...response, fetching: true })
    signUp({ email, password: password1 })
      .then((content) => {
        setResponse((r) => {
          return { ...r, content }
        })
      })
      .catch((e) => {
        console.error(e)
        window.alert(e)
      })
      .then(() => {
        setResponse((r) => {
          return { ...r, fetching: false }
        })
      })
  }

  return (
    <>
      <PageHeaderModal>Sign up to Dateno</PageHeaderModal>

      {hasProviders ? (
        <>
          <div className="text-center">
            <ProviderList signup callbackURL="/account/provider/callback" />
          </div>
          <div className="text-center text-body-tertiary small p-2">or create a Dateno account with email</div>
        </>
      ) : null}

      <FormErrors errors={response.content?.errors} />

      <form>
        <div className="mb-3">
          <label htmlFor="exampleInputEmail1" className="form-label  small px-2">
            Email address
          </label>
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            className="form-control"
            id="exampleInputEmail1"
            // aria-describedby="emailHelp"
            required
          />
          {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
          <FormErrors param="email" errors={response.content?.errors} />
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label  small px-2">
            Password
          </label>
          <input
            autoComplete="new-password"
            value={password1}
            onChange={(e) => setPassword1(e.target.value)}
            type="password"
            className="form-control"
            id="exampleInputPassword1"
            required
          />
          <FormErrors param="password" errors={response.content?.errors} />
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label  small px-2">
            Password (again){" "}
          </label>
          <input
            autoComplete="new-password"
            value={password2}
            onChange={(e) => setPassword2(e.target.value)}
            type="password"
            className="form-control"
            id="exampleInputPassword2"
            required
          />
          <FormErrors param="password2" errors={password2Errors} />
        </div>
        <div className="d-grid gap-2 mb-3">
          <Button
            disabled={!email || !password1 || !password2 || response.fetching}
            className="btn btn-primary"
            type="submit"
            onClick={() => submit()}
          >
            Create account
          </Button>
        </div>
      </form>

      <p className="small text-center m-0">
        Already have an account?{" "}
        <Link className="link-underline-light-custom link-offset-1" to="/account/login">
          Sign in
        </Link>
      </p>
    </>
  )
}
