// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Logo_logo-wrapper__rneju {
  padding-top: 1rem;
  padding-left: 1rem;
  gap: 1rem;
  flex: 0 0 auto;
  display: flex;
  text-decoration: none;
  box-sizing: border-box;
  color: buttontext;
}

.Logo_logo-wrapper__rneju svg {
  color: buttontext;
  display: flex;
  width: 2rem;
  height: 2rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/Logo.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,SAAS;EACT,cAAc;EACd,aAAa;EACb,qBAAqB;EACrB,sBAAsB;EACtB,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,WAAW;EACX,YAAY;AACd","sourcesContent":[".logo-wrapper {\n  padding-top: 1rem;\n  padding-left: 1rem;\n  gap: 1rem;\n  flex: 0 0 auto;\n  display: flex;\n  text-decoration: none;\n  box-sizing: border-box;\n  color: buttontext;\n}\n\n.logo-wrapper svg {\n  color: buttontext;\n  display: flex;\n  width: 2rem;\n  height: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo-wrapper": `Logo_logo-wrapper__rneju`
};
export default ___CSS_LOADER_EXPORT___;
