import { useState, useEffect } from "react"
import { URLs } from "../utils/userapi"

const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
)
const Subscribe = () => {
  const [message, setMessage] = useState("")
  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search)

    if (query.get("success")) {
      setMessage("Order placed! You will receive an email confirmation.")
    }

    if (query.get("canceled")) {
      setMessage("Order canceled -- continue to shop around and checkout when you're ready.")
    }
  }, [])

  return message ? (
    <Message message={message} />
  ) : (
    <form action={URLs.CREATE_CHECKOUT_SESSION} method="POST">
      <h5 className="fw-light">$5.00</h5>
      <button className="btn btn-primary" type="submit">
        Subscribe
      </button>
    </form>
  )
}

export default Subscribe
