const MEILISEARCH_URL = process.env.REACT_APP_MEILISEARCH_URL || "https://ms15.dateno.io"
const MEILISEARCH_TOKEN = process.env.REACT_APP_MEILISEARCH_TOKEN
const MEILISEARCH_INDEX_NAME = process.env.REACT_APP_MEILISEARCH_INDEX_NAME || "fulldb:scores.feature_score:desc"
const API_URL = process.env.REACT_APP_API_URL || "https://api.dateno.io"
const DATENO_FREE_SEARCH_URL = process.env.REACT_APP_DATENO_FREE_SEARCH_URL || "https://dateno.io"
const CSRF_COOKIE_NAME = process.env.REACT_APP_CSRF_COOKIE_NAME || "csrftoken"

export const Settings = Object.freeze({
  DATENO_PUBLIC_API_URL: API_URL, // alias
  MEILISEARCH_URL,
  MEILISEARCH_TOKEN,
  MEILISEARCH_INDEX_NAME,
  DATENO_FREE_SEARCH_URL,
  CSRF_COOKIE_NAME,
})

export default Settings
