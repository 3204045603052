import { Link } from "react-router-dom"

import NavBar from "./NavBar"
import Footer from "./Footer"
import Logo from "./Logo"
import PageHeader from "./PageHeader"

const Header = ({ title }) => (
  <header className="container-fluid">
    <div className="row">
      <div className="sidebar navbar-brand  col-md-3 col-lg-2 p-0 bg-body-tertiary">
        <Link to="/" className="offcanvas-md col-md-3 col-lg-2 me-0 px-0 link-underline link-underline-opacity-0">
          <Logo />
        </Link>
      </div>
      <div className="pagetitle col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <div className="navbar sticky-top flex-md-nowrap p-0">
          <span className="navbar-brand">
            <PageHeader>{title}</PageHeader>
          </span>
          <Toggler />
        </div>
      </div>
    </div>
  </header>
)

const Toggler = () => (
  <ul className="navbar-nav flex-row d-md-none">
    <li className="nav-item text-nowrap">
      <button
        className="nav-link px-3 "
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#sidebarMenu"
        aria-controls="sidebarMenu"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className=" navbar-toggler-icon"></span>
      </button>
    </li>
  </ul>
)

export default function PageLayout({ title, children }) {
  return (
    <>
      <Header title={title} />
      <div className="container-fluid">
        <div className="row">
          <aside className="sidebar col-md-3 col-lg-2 p-0">
            <div
              className="offcanvas-md offcanvas-end"
              tabIndex="-1"
              id="sidebarMenu"
              aria-labelledby="sidebarMenuLabel"
            >
              <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="sidebarMenuLabel">
                  <Logo />
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="offcanvas"
                  data-bs-target="#sidebarMenu"
                  aria-label="Close"
                ></button>
              </div>
              <div className="offcanvas-body d-md-flex flex-column p-0 pt-lg-3 overflow-y-auto">
                <NavBar />
              </div>
            </div>
          </aside>
          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-2">{children}</main>
        </div>
      </div>
      <Footer />
    </>
  )
}
