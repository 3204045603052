import React from "react"
import { InstantSearch, SearchBox, InfiniteHits, Highlight, RefinementList, Configure } from "react-instantsearch"
import { instantMeiliSearch } from "@meilisearch/instant-meilisearch"
import "instantsearch.css/themes/satellite.css"
import { getRouting, getOnStateChange } from "./customRouting"
import Settings from "../utils/settings"

const searchIndexName = Settings.MEILISEARCH_INDEX_NAME
const { searchClient } = instantMeiliSearch(Settings.MEILISEARCH_URL, Settings.MEILISEARCH_TOKEN)

const Search = () => (
  <InstantSearch
    indexName={searchIndexName}
    searchClient={searchClient}
    routing={getRouting(searchIndexName)}
    onStateChange={getOnStateChange(searchIndexName)}
  >
    <Configure hitsPerPage={8} />

    <div style={{ display: "flex" }}>
      <div style={{ minWidth: "170px" }}>
        <RefinementList attribute="dataset.formats" />
      </div>
      <div style={{ flex: 1 }}>
        <SearchBox />
        <InfiniteHits
          hitComponent={Hit}
          showPrevious={false}
          future={{
            preserveSharedStateOnUnmount: true,
          }}
        />
      </div>
    </div>
  </InstantSearch>
)

// https://www.algolia.com/doc/api-reference/widgets/infinite-hits/react/
// TODO warning with missing key for li element
// Maybe there is <></> (React.Fragment) simewhere in the react-instansearch codebase re Hit component

function Hit({ hit }) {
  return (
    <article key={hit.id}>
      <Highlight attribute="name" hit={hit} />

      <h3 className="fw-light">
        <Highlight attribute="dataset.title" hit={hit} />
      </h3>
      {hit.responsible ? (
        <p>
          {hit.responsible[0]?.role}:&nbsp; {hit.responsible[0]?.title}
        </p>
      ) : null}
      <p className="text-secondary">
        Source:&nbsp;
        <Highlight attribute="source.name" hit={hit} />
      </p>
    </article>
  )
}

export default Search
