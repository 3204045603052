import { Link, useRouteError, useNavigate } from "react-router-dom"
import { useUser } from "../auth"
import PageLayout from "./PageLayout"
import PageLayoutAnon from "./PageLayoutAnon"
import MainWrap from "./MainWrap"

const HelpMessage = ({ error }) => {
  const navigate = useNavigate()
  return (
    <section className="text-center my-3">
      <h1 className="fs-1 fw-light">{error?.status}</h1>
      <p>Oops, it seems we couldn't find what you were looking for.</p>
      <p>Please check the link and try again!</p>
      <p>
        <Link to="/">Go home</Link> or{" "}
        <Link className=" icon-left" onClick={() => navigate(-1)}>
          Go back
        </Link>
      </p>
    </section>
  )
}

const ErrorBoundary = () => {
  const user = useUser()
  const error = useRouteError()

  return user ? (
    <PageLayout title="Missing Page">
      <MainWrap NoticePanel={<>{error?.data?.replace("Error: ", "")}</>}>
        <HelpMessage error={error} />
      </MainWrap>
    </PageLayout>
  ) : (
    <PageLayoutAnon>
      <HelpMessage />
    </PageLayoutAnon>
  )
}
export default ErrorBoundary
