import { Navigate, useLocation, Link } from "react-router-dom"
import { URLs, pathForPendingFlow, useAuthStatus } from "../auth"
// import { useEffect } from "react";

export default function ProviderCallback() {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const error = params.get("error")
  const [auth, status] = useAuthStatus()

  let url = URLs.LOGIN_URL
  if (status.isAuthenticated) {
    url = URLs.LOGIN_REDIRECT_URL
  } else {
    url = pathForPendingFlow(auth) || url
  }

  // useEffect(() => {
  //   const ext_front = `http://localhost:5173/dashboard?accessToken=TOKEN&email=${status.user.email}&isAuthenticated=${status.isAuthenticated}`;
  //   window.location.href = ext_front
  // }, [status]);

  if (!error) {
    // return
    return <Navigate to={url} />
  }
  return (
    <>
      <h1 className="fw-light">Third-Party Login Failure</h1>
      <p>Something went wrong.</p>
      <p>{error}</p>
      <Link to={url}>Continue</Link>
    </>
  )
}
