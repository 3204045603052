import { useEffect } from "react"
import { useOutletContext } from "react-router-dom"

export const useTitle = (title) => {
  const { setTitle } = useOutletContext()

  useEffect(() => {
    setTitle(title)
  }, [setTitle, title])
}
