import { useState } from "react"
import FormErrors from "../components/FormErrors"
import { changePassword } from "../utils/allauth"
import { Navigate, Link } from "react-router-dom"
import { useUser } from "../auth"
import { URLs } from "../auth/routing"
import Button from "../components/Button"
import MainWrap from "../components/MainWrap"

export default function ChangePassword() {
  const hasCurrentPassword = useUser().has_usable_password
  const [currentPassword, setCurrentPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [newPassword2, setNewPassword2] = useState("")
  const [newPassword2Errors, setNewPassword2Errors] = useState([])

  const [response, setResponse] = useState({ fetching: false, content: null })

  function submit() {
    if (newPassword !== newPassword2) {
      setNewPassword2Errors([{ param: "new_password2", message: "Password does not match." }])
      return
    }
    setNewPassword2Errors([])
    setResponse({ ...response, fetching: true })
    changePassword({ current_password: currentPassword, new_password: newPassword })
      .then((resp) => {
        setResponse((r) => {
          return { ...r, content: resp }
        })
      })
      .catch((e) => {
        console.error(e)
        window.alert(e)
      })
      .then(() => {
        setResponse((r) => {
          return { ...r, fetching: false }
        })
      })
  }
  if (response.content?.status === 200) {
    return <Navigate to={URLs.LOGIN_REDIRECT_URL} />
  }
  const passwordsProvided = hasCurrentPassword
    ? currentPassword & newPassword & newPassword2
    : newPassword & newPassword2
  return (
    <MainWrap
      NoticePanel={
        <>
          Back to <Link to="/settings">Account Settings</Link>
        </>
      }
    >
      <p>
        {hasCurrentPassword
          ? "Enter your current password, followed by your new password."
          : "You currently have no password set. Enter your (new) password here."}
      </p>

      <form>
        {hasCurrentPassword ? (
          <div className="mb-3">
            <label htmlFor="currentPassword" className="form-label">
              Current password
            </label>
            <input
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              type="password"
              className="form-control"
              id="currentPassword"
              required
            />
            <FormErrors param="current_password" errors={response.content?.errors} />
          </div>
        ) : null}

        <div className="mb-3">
          <label htmlFor="newPassword1" className="form-label">
            Password
          </label>
          <input
            autoComplete="new-password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            type="password"
            className="form-control"
            id="newPassword1"
            required
          />
          <FormErrors param="new_password" errors={response.content?.errors} />
        </div>
        <div className="mb-3">
          <label htmlFor="newPassword2" className="form-label">
            Password (again){" "}
          </label>
          <input
            autoComplete="new-password"
            value={newPassword2}
            onChange={(e) => setNewPassword2(e.target.value)}
            type="password"
            className="form-control"
            id="newPassword2"
            required
          />
          <FormErrors param="new_password2" errors={newPassword2Errors} />
        </div>

        <div className="">
          <Button type="submit" disabled={!passwordsProvided || response.fetching} onClick={() => submit()}>
            {hasCurrentPassword ? "Change password" : "Set password"}
          </Button>
        </div>
      </form>
    </MainWrap>
  )
}
