import MockSubscribe from "./MockSubscribe"
import Subscribe from "./Subscribe"

const TEST_MODE = true
const SubscribeWidget = TEST_MODE ? MockSubscribe : Subscribe

export default function Checkout() {
  return (
    <>
      <div className="description">
        <p>
          Unlock global open data with ease. Our cutting-edge data search service offers quick, comprehensive access to
          datasets worldwide, featuring advanced filters and API-first design for effortless integration.
        </p>
      </div>

      <SubscribeWidget />
    </>
  )
}
