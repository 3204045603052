import Settings from "./settings"

export function getPublicPageUrl(name) {
  if (!name) {
    return Settings.DATENO_FREE_SEARCH_URL
  }
  return `${Settings.DATENO_FREE_SEARCH_URL}/${name}/`
}

export function getSearchUrl(queryString) {
  const params = queryString ? "?" + queryString : ""
  return getPublicPageUrl("search") + params
}

export function getPrivacyUrl() {
  return getPublicPageUrl("privacy")
}

export function getApiDocsUrl() {
  return Settings.DATENO_PUBLIC_API_URL
}
