import { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import dayjs from "dayjs"
import { getSearchHistoryRecordList, deleteSearchHistoryRecord } from "./utils/restapi"
import { getSearchUrl } from "./utils/helpers"
import { queryToObject } from "./search/customRouting"
import MainWrap from "./components/MainWrap"

// dateno-frontend...DemoSearchFilters...filters.forEach(fi => names[fi.name]=fi.display_name)
const facetNames = {
  "dataset.datatypes": "Datatypes",
  "dataset.formats": "Format",
  "dataset.geotopics": "Topic Category",
  "dataset.license_id": "License",
  "dataset.topics": "Data theme",
  "source.catalog_type": "Catalog type",
  "source.countries.name": "Country",
  "source.langs.name": "Language",
  "source.macroregions.name": "Macroregion",
  "source.name": "Source",
  "source.owner_type": "Who owns data",
  "source.software.name": "Software",
  "source.subregions.name": "Subregion",
}

const SavedRefinements = ({ refinementList }) => {
  if (!refinementList) {
    return null
  }
  return (
    <>
      <span className="text-body-tertiary">
        {Object.keys(refinementList).map((key) => (
          <span key={key} className="small">
            {facetNames[key] || key}: <span className="not-text-body-secondary">{refinementList[key].join(", ")}</span>{" "}
          </span>
        ))}
      </span>
    </>
  )
}

const Query = ({ query }) => {
  const obj = queryToObject(query)
  return (
    <div>
      <Link to={getSearchUrl(query)}>{obj.query}</Link> <SavedRefinements refinementList={obj.refinementList} />
    </div>
  )
}

const HistoryRecord = ({ record, handleDelete }) => {
  // console.log(dayjs,dayjs(record.created_at),record.created_at )
  return (
    <tr>
      <td>
        <div role="row" className="row">
          <div className="col-1 text-body-tertiary small">{dayjs(record.created_at).format("MMM D")}</div>
          <div className="col-1 text-body-tertiary small">{dayjs(record.created_at).format("HH:mm:ss")}</div>
          <div className="col">
            <Query query={record.query} />
          </div>
          <div className="col-1">
            <button className="btn" onClick={() => handleDelete(record.id)}>
              Delete
            </button>
          </div>
        </div>
      </td>
    </tr>
  )
}

const SearchHistory = () => {
  const [notes, setNotes] = useState([])
  const [response, setResponse] = useState([])
  const [error, setError] = useState(null)

  useEffect(() => {
    fetchNotes()
  }, [])

  const fetchNotes = async (params) => {
    try {
      const response = await getSearchHistoryRecordList(params)
      setNotes(response.results)
      setResponse(response)
    } catch (error) {
      setError("Failed to fetch notes")
    }
  }

  const handleFetchNext = async () => {
    fetchNotes(response.next)
  }
  const handleFetchPrevious = async () => {
    fetchNotes(response.previous)
  }

  const handleDelete = async (noteId) => {
    try {
      await deleteSearchHistoryRecord(noteId)
      await fetchNotes()
      // setNotes(notes.filter(note => note.id !== noteId));
    } catch (error) {
      setError("Failed to delete note")
    }
  }

  return (
    <MainWrap NoticePanel={<>Records in search history: {response.count}</>} width="auto">
      {error && <p className="invalid-feedback d-block">{error}</p>}
      <div>
        {response.previous ? (
          <button onClick={handleFetchPrevious} className="btn">
            Show previous
          </button>
        ) : null}
      </div>
      {response.count ? (
        <table className="table bg-white table-hover table-borderless align-middle">
          <tbody className="container">
            {notes?.map((note) => (
              <HistoryRecord key={note?.id} record={note} handleDelete={handleDelete} />
            ))}
          </tbody>
        </table>
      ) : (
        <p>
          No history yet. Search for <Link to={getSearchUrl("query=russian%20gas")}>Russian gas</Link>
        </p>
      )}
      <div>
        {response.next ? (
          <button onClick={handleFetchNext} className="btn">
            Show more
          </button>
        ) : null}
      </div>
    </MainWrap>
  )
}

export default SearchHistory
