import { useState } from "react"
import FormErrors from "../components/FormErrors"
import { providerSignup } from "../utils/allauth"
import { Link } from "react-router-dom"
import Layout from "../account/Layout"

const DEFAULT_RESPONSE = { fetching: false, content: null }
export default function ProviderSignup() {
  const [email, setEmail] = useState("")
  const [error, setError] = useState()
  const [response, setResponse] = useState(DEFAULT_RESPONSE)

  function submit() {
    setResponse({ ...response, fetching: true })
    providerSignup({ email })
      .then((content) => {
        if (content.status > 299) {
          setError(true)
        }
        setResponse((r) => {
          return { ...r, content }
        })
      })
      .catch((e) => {
        console.error(e)
        window.alert(e)
      })
      .then(() => {
        setResponse((r) => {
          return { ...r, fetching: false }
        })
      })
  }

  function handleChange(e) {
    setEmail(e.target.value)
    setError(false)
    setResponse(DEFAULT_RESPONSE)
  }

  return (
    <Layout>
      <FormErrors errors={response.content?.errors} />

      <form>
        <div className="mb-3">
          <label htmlFor="exampleInputEmail1" className="form-label">
            To continue, please provide your email address
          </label>
          <input
            value={email}
            onChange={handleChange}
            type="email"
            className="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            required
          />
          {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
          <FormErrors param="email" errors={response.content?.errors} />
        </div>
        <div className="d-grid gap-2">
          {error && !response.content?.errors ? (
            <div className="small alert alert-danger mt-3" role="alert">
              Sorry. Something didn't go right. Please, start <Link to="/account/signup">signing up again.</Link>
            </div>
          ) : null}
          <button
            disabled={!email || error || response.fetching}
            className="btn btn-primary"
            type="submit"
            onClick={() => submit()}
          >
            Finalise sign up
          </button>
          <p>
            Already have an account? <Link to="/account/login">Sign in →</Link>
          </p>
        </div>
      </form>
    </Layout>
  )
}
